import _ from "lodash";
import {getQuestionAnswers, getStepCategory, getStepType, getUID, noAnswer, requiredCheck} from "../../utils";
import {STEP_TYPE} from "../../constants";

export class MenuItem {
  constructor(uid, name, getPrevStepAnswer) {
    this._uid = uid;
    this._name = name;
    this._step = null;
    this._isFirstStep = false;
    this._isLastStep = false;
    this._progress = 0;
    this._is_finished = false;
    this._questionnaire = undefined;
    this._getPrevStepAnswer = getPrevStepAnswer;
    this.__isCustomAnswers = true;
    this.__is_new_design = undefined;
  }

  get name() {
    return this._name;
  }

  get uid() {
    return this._uid;
  }

  get finished() {
    return this._is_finished;
  }

  set finished(value) {
    this._is_finished = value;
  }

  get step() {
    return this._step;
  }

  get progress() {
    return this._progress;
  }

  get currentStepNumber() {
    return this._curr_step_index;
  }

  get steps(){
    return this._questionnaire && this._questionnaire.steps || [];
  }

  get stepsLength() {
    return this._questionnaire && this._questionnaire.steps && this._questionnaire.steps.length || 0;
  }

  get isFistStep() {
    return this._isFirstStep;
  }

  get isLastStep() {
    return this._isLastStep;
  }

  get isDone(){
    // we assume that last step is congrats and not a question
    return (this._isLastStep && this.step && getStepType(this.step) === STEP_TYPE.CONGRATS) || this._is_finished;
  }

  get isNewDesign() {
    return this.__is_new_design;
  }

  get hasMissingAnswers(){
    return !!this._questionnaire && this._questionnaire.steps.some(this.checkHasMissingAnswers);
  }

  checkHasMissingAnswers = (q) => {
    return _.isArray(q.question) ? q.question.some(this.checkHasMissingAnswers) : this._checkRequiredQuestionMissing(q)
  };

  getQuestionsWithoutAnswers(){
    const missingData = [];
    if(this._questionnaire){
      this._questionnaire.steps.forEach(step => {
        const questionsWithMissingAnswers = this._getQuestionsWithMissingAnswers(step);

        if(!_.isEmpty(questionsWithMissingAnswers)) {
          missingData.push({
            name: getStepCategory(step),
            uid: step.uid,
            parent: step.parent,
            questions: questionsWithMissingAnswers,
          })
        }
      });
    }

    return missingData;
  }

  _getQuestionsWithMissingAnswers = (q) => {
    if(_.isArray(q.question)){
      return _.flatten(q.question.map(question => this._getQuestionsWithMissingAnswers(question)).filter(Boolean));
    } else if(this._checkRequiredQuestionMissing(q)){
      return [q];
    }
  };

  _checkRequiredQuestionMissing = (q) => requiredCheck(q) && noAnswer(q.answer);

  async initQuestionnaire(defaultAnswers, initOnly, lastFinishedStep) {
    await this.__setQuestionnaire(defaultAnswers, initOnly);

    if(!!initOnly) {
      const lastStepIdx = this._questionnaire && this._questionnaire.steps && this._questionnaire.steps.length - 1 || 0;
      await this.__setStep(null, lastStepIdx);
      this._is_finished = true;
      return;
    } else {
      // Note: pass it ONLY when init (restore) progress and ignore for Interact
      if(!!lastFinishedStep && this.__isCustomAnswers){
        const steps = this._questionnaire && this._questionnaire.steps || [];
        const lastStepIdx = steps.findIndex(item => item.uid === lastFinishedStep);
        if(lastStepIdx !== -1){
          // set current step as next after last finished
          this._step = steps[lastStepIdx + 1];
        }
      }
    }

    await this.sendCurrentStep();

    if (this._step){
      await this.__setStep(0)
    } else {
      await this.__setStep(null, 0)
    }
  }

  async sendCurrentStep(){
    // set current step in backend - to store progress
  }

  async __setQuestionnaire(defaultAnswers, initOnly){

  }

  async __sendStepData() {

  }

  async nextStep() {
    const latestOnboardingData = await this.__sendStepData();
    if(this.isLastStep) {
      this._is_finished = true;
    } else {
      await this.__setStep(1);
    }

    return latestOnboardingData
  }

  async prevStep() {
    await this.__setStep(-1);
  }

  async goToStep(step_uid) {
    if(this._questionnaire && this._questionnaire.steps) {
      const index = this._questionnaire.steps.findIndex(step => step.uid === step_uid);
      if(index !== -1) {
        await this.__setStep(null, index);
      }
    }
  }

  async __setStep(direction, index) {
    if(this._questionnaire && this._questionnaire.steps) {
      const stepsLength = this._questionnaire.steps.length;
      const currentIndex = this._step && this._questionnaire.steps.findIndex(item => item.uid === this._step.uid) || 0;
      const requestedIndex = typeof index == 'number' ? index : currentIndex + direction;

      if (this._questionnaire.steps[requestedIndex]) {
        this._isFirstStep = requestedIndex === 0;
        this._isLastStep = requestedIndex === (stepsLength - 1);
        this._step = this._questionnaire.steps[requestedIndex];
        this._progress = Math.round(requestedIndex / (stepsLength - 1) * 10000) / 100;
        this._curr_step_index = requestedIndex + 1;
      }
    }
  }

  _getStepUID = (uid) => {
    return this.__isCustomAnswers ? uid : getUID(uid);
  };

  _getQuestionUID = (uid) => {
    if (!this.__isCustomAnswers) {
      if (!['final_question_uid'].includes(uid)) {
        uid = getUID(uid);
      }
    }
    return uid;
  };

  getStep(stepUID) {
    stepUID = this._getStepUID(stepUID);
    return this._questionnaire && this._questionnaire.steps.find(step => this._getStepUID(step.uid) === stepUID)
  }

  findQuestionDeep(q, questionUID) {
    for(let question_index=0; question_index < (q.question || []).length; question_index++){
      let result;
      let nested_question = q.question[question_index];
      if(this._getQuestionUID(nested_question.uid) === questionUID){
        result = nested_question;
      } else if (!_.isEmpty(nested_question.question)){
        result = this.findQuestionDeep(nested_question, questionUID)
      }

      if (!_.isNil(result)) return result
    }
  }

  getQuestion(stepUID, questionUID){
    questionUID = this._getQuestionUID(questionUID);

    const step = this.getStep(stepUID);
    return step && this.findQuestionDeep(step, questionUID);
  }

  getStepAnswer(stepUID, questionUID, withOption, asQuestion=false){
    const question = this.getQuestion(stepUID, questionUID);
    if(question) {
      if (asQuestion) return question
      const answerData = getQuestionAnswers(question, withOption);
      if (answerData && answerData.answer) {
        return answerData.answer[0]
      } else {
        if (Array.isArray(answerData)) {
          return answerData
        }
      }
    }
  }

  __deepCopy = (data) => {
    return _.cloneDeep(data);
  };
}