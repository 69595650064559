import _ from 'lodash';

import {
  BANK_ACCOUNT_UID,
  CLEARING_ACCOUNT_BALANCING_QUESTION_UID,
  CLEARING_ACCOUNT_OPTION_QUESTION_UID,
  CLEARING_ACCOUNT_QUESTION_UID,
  EMPLOYER_STEP_ID,
  FIELD_DOES_NOT_FITT_MSG,
  FIELD_REQUIRED_MSG,
  NOTES_FOR_ORIGIN_OF_ASSETS_UID,
  ORIGIN_OF_ASSETS_UID,
  REF_ACCOUNT_STEP_ID
} from "../../constants";
import ReferenceAccountStep from "../StepContent/components/step/CustomerDataSteps/ReferenceAccountStep";
import {validateMultilineContent} from "../../utils";
import EmployerInformationStep from "../StepContent/components/step/CustomerDataSteps/EmployerInformationStep";

const BANK_FEE_OPTIONS_NAMES = {
  'bank_account': 'Referenzkonto',
  'clearing_account': 'Verrechnungskonto',
  'sale_via_quantities': 'Verkauf über Anteile',
};

export const buildDataSteps = (isVlPlan, chargingBankFeeOptions) => {
  const steps = [{
    "uid": REF_ACCOUNT_STEP_ID,
    "name": "Bitte wählen Sie ein Referenzkonto aus",
    "behaviour": ["dynamic", "Referenzkonto"],
    "step_content": ReferenceAccountStep,
    "question": [
      {
        "question_text": "Verrechnungskonto auswählen:",
        "uid": CLEARING_ACCOUNT_QUESTION_UID,
        "custom_classes": {"labelRoot": "bold"},
        "optional": true,
        "question": [] // list of checkbox questions. Should be filled with questions in data service, as it depends on selected portfolios and related clearing accounts
      },
      {
        "uid": CLEARING_ACCOUNT_OPTION_QUESTION_UID,
        "optional": true,
        "question": [] // list of SELECT questions. Should be filled with questions in data service, as it depends on selected portfolios and related clearing accounts
      },
      {
        "uid": CLEARING_ACCOUNT_BALANCING_QUESTION_UID,
        "optional": true,
        "question": [] // list of checkbox questions. Should be filled with questions in data service, as it depends on selected portfolios and related clearing accounts
      },
      {
        "uid": BANK_ACCOUNT_UID,
        "type": "reference-accounts",
        "question_text": "Gespeichertes Referenzkonto auswählen:",
        "custom_classes": {"labelRoot": "bold"},
        "bank_accounts": [],
        "size": 8,
        "optional": false
      }
    ]
  }];

  if(_.isArray(chargingBankFeeOptions) && chargingBankFeeOptions.length > 1){
    steps.push({
      "uid": "charging_bank_fees_step",
      "name": "Auswahl der Belastung für Bankentgelte *",
      "behaviour": ["dynamic", "Bankentgelte"],
      "question": [
        {
          "uid": "charging_bank_fees_source",
          "type": "normal",
          "behaviour": ["radio"],
          "optional": false,
          "row": false, // use "column" layout for options
          "config": {
            "choices": chargingBankFeeOptions.map(option => ({
              id: option,
              uid: option,
              text: BANK_FEE_OPTIONS_NAMES[option] || _.upperFirst(option).replace('_', ' ')
            }))
          }
        }
      ]
    });
  }

  steps.push({
    "uid": ORIGIN_OF_ASSETS_UID,
    "name": "Herkunft der Vermögenswerte",
    "behaviour": ["dynamic", "Herkunft der Vermögenswerte"],
    "step_content": ReferenceAccountStep,
    "question": [
      {
        "uid": ORIGIN_OF_ASSETS_UID,
        "question_text": "Herkunft der Vermögenswerte",
        "question_info": "Bitte beachten Sie, dass die Depotstelle gegebenfalls Belege zur Herkunft des Vermögens einfordern.",
        "question": [
          {
            "uid": "origin_of_assets['saved_assets']",
            "type": "checkbox",
            "question_text": "Angespartes Vermögen",
          },
          {
            "uid": "origin_of_assets['wages_and_income']",
            "type": "checkbox",
            "question_text": "Lohn, Einkommen",
          },
          {
            "uid": "origin_of_assets['rental_and_leasing']",
            "type": "checkbox",
            "question_text": "Vermietung und Verpachtung",
          },
          {
            "uid": "origin_of_assets['investment_income']",
            "type": "checkbox",
            "question_text": "Kapitalerträge /gewerbl. Tätigkeit",
          },
          {
            "uid": "origin_of_assets['special_payments']",
            "type": "checkbox",
            "question_text": "Sonderzahlungen / Auszahlungen Lebensvers.",
          },
          {
            "uid": "origin_of_assets['sale_of_real_estate']",
            "type": "checkbox",
            "question_text": "Verkauf von Immobilien / Firmen",
          },
          {
            "uid": "origin_of_assets['heritage']",
            "type": "checkbox",
            "question_text": "Erbe",
          },
          {
            "uid": "origin_of_assets['others']",
            "type": "checkbox",
            "question_text": "Sonstiges",
          },
        ],
        "isValid": function () {
          if (!this.optional) {
            const isValid = this.question.some(q => q.answer);
            this.error = isValid ? null : FIELD_REQUIRED_MSG;
            return isValid;
          }
          return true
        }
      },
      {
        "uid": NOTES_FOR_ORIGIN_OF_ASSETS_UID,
        "question_text": "Anmerkungen zur Herkunft der Vermögenswerte",
        "question_info": "Sofern insbesondere risikobasiert eine Erklärung und/oder Erläuterung zur Herkunft der Vermögenswerte erfolgen soll, tragen Sie diese oder sonstige Anmerkungen bitte hier ein.",
        "type": "input",
        "config": {
          "multiline": true
        },
        "validateImmediately": true,
        "isValid": function () {

          let isValid = true
          this.error = null

          if (this.answer) {
            isValid = validateMultilineContent(this.answer, 100)
            this.error = isValid ? null : FIELD_DOES_NOT_FITT_MSG
          }

          return isValid
        }
      }
    ]
  });

  if (isVlPlan) {
    steps.push({
      "uid": EMPLOYER_STEP_ID,
      "behaviour": ["dynamic", "Arbeitgeber Daten"],
      "name": "Angaben zum Arbeitgeber",
      "step_content": EmployerInformationStep,
      "question": [
        {
          "uid": "employer['company_name']",
          "question_text": "Arbeitgeber / Firma ",
          "type": "input",
          "size": 6,
          "config": {
            "input_type": "",
            "mapping": {}
          },
          "optional": true
        },
        {
          "uid": "employer['phone_number']",
          "question_text": "Telefonnummer",
          "type": "input",
          "size": 6,
          "behaviour": ["tel"],
          "config": {
            "input_type": "tel",
            "mapping": {}
          },
          "optional": true
        },
        {
          "uid": "employer['street']",
          "question_text": "Straße",
          "type": "input",
          "size": 6,
          "config": {
            "input_type": "",
            "mapping": {}
          },
          "optional": true
        },
        {
          "uid": "employer['street_number']",
          "question_text": "Hausnr.",
          "type": "input",
          "size": 6,
          "config": {
            "input_type": "tel",
            "mapping": {}
          },
          "optional": true
        },
        {
          "uid": "employer['zip_code']",
          "question_text": "PLZ",
          "type": "input",
          "size": 6,
          "config": {
            "input_type": "tel",
            "mapping": {}
          },
          "optional": true
        },
        {
          "uid": "employer['city']",
          "question_text": "Ort",
          "type": "input",
          "size": 6,
          "config": {
            "input_type": "",
            "mapping": {}
          },
          "optional": true
        },
        {
          "uid": "employer['employee_id']",
          "question_text": "Personalnummer",
          "type": "input",
          "size": 6,
          "config": {
            "input_type": "",
            "mapping": {}
          },
          "optional": true
        }
      ]
    });
  }

  return {
    steps: steps
  }
};