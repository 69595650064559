import React from "react";
import _ from 'lodash'

import {CustomerService} from "../../RiskProfiling/customer_step_service";
import {
  buildPortfolioClearingAccountQuestion,
  getUID,
  isLegitimationQuestionsEmpty,
  orderClearingAccountAllowed,
  isLegitimationQuestionOutdated,
  setContactsOptionalFlag,
  buildPortfolioClearingAccountBalancingQuestion,
  clearingAccountBalancingAllowed, orderClearingAccountToBankAccountAllowed, buildPortfolioClearingAccountOptionQuestion
} from "../../RiskProfiling/utils";
import {
  getTransactionsTotalAmount,
  setPortfolioClearingAccount
} from "../utils";
import {
  CLEARING_ACCOUNT_BALANCING_QUESTION_UID,
  CLEARING_ACCOUNT_OPTION_QUESTION_UID,
  CLEARING_ACCOUNT_QUESTION_UID,
  LEGITIMATION_DOCUMENTS_STEP_IDENTIFIERS,
  buildStepUIDForMember,
  REF_ACCOUNT_STEP_ID,
  ORIGIN_OF_ASSETS_UID,
  ORIGIN_OF_ASSETS_EVIDENCE_UID,
  NOTES_FOR_ORIGIN_OF_ASSETS_UID, LEGITIMATION_STEP_ID
} from "../../RiskProfiling/constants";
import {getMemberIndexFromStepUID} from "../../RiskProfiling/services";
import {TRANSACTION_TYPE_VALUES} from "../constants";
import {buildQuestionUIDForMember} from "../../RiskProfiling/service";


export class CustomerMenuItem extends CustomerService {
  constructor(uid, name, customer_id, getPrevStepAnswer, validateAccountFunc) {
    super(uid, name, "Weiter zu Aufklärung & Protokoll",
      <>
        Die Kundendaten wurden erfolgreich gespeichert. Fahren Sie fort mit Aufklärung &
        Protokoll um den Prozess erfolgreich abschließen zu können.
      </>,
      getPrevStepAnswer, validateAccountFunc);
    this._customer_id = customer_id;
    this._isIdentificationOutdated = false
    this.is_trading = true
  }

  get isIdentificationOutdated() {
    return this._isIdentificationOutdated
  }

  get banksData() {
    let step = this._getPrevStepAnswer('trade', 'trade-step', 'transactions', false, true)
    if (step) {
      return step.banksData || []
    }
    return []
  }

  get portfolios() {
    let answer = this._getPrevStepAnswer('trade', 'trade-step', "transactions", true);

    if (!!answer) {
      answer.forEach((portfolio) => {
        setPortfolioClearingAccount(portfolio, (p) => p.data && p.data.clearing_account || []);
        portfolio.orderClearingAccountAllowed = orderClearingAccountAllowed(
          this.banksData, portfolio.companyId);
        portfolio.clearingAccountBalancingAllowed = clearingAccountBalancingAllowed(
          this.banksData, portfolio.companyId);
        portfolio.orderClearingAccountToBankAccountAllowed = orderClearingAccountToBankAccountAllowed(
          this.banksData, portfolio.companyId);
      });

      return answer;
    }

    return [];
  }

  makeContactsInformationOptional() {
    setContactsOptionalFlag(this._questionnaire.steps, true);
  }

  cleanReferenceOptions() {
    const questionsToClean = [ORIGIN_OF_ASSETS_UID, ORIGIN_OF_ASSETS_EVIDENCE_UID, NOTES_FOR_ORIGIN_OF_ASSETS_UID];

    this._questionnaire.steps.forEach(step => {
      if ([REF_ACCOUNT_STEP_ID, ORIGIN_OF_ASSETS_UID].includes(getUID(step.uid))) {
        step.question.forEach(stepQuestion => {
          if (questionsToClean.includes(stepQuestion.uid)) {
            stepQuestion.answer = null
            if (stepQuestion.hasOwnProperty('question') && stepQuestion.question.length) {
              stepQuestion.question.map(subQuestion => {
                subQuestion.answer = null
              })
            }
          }
        })
      }
    })
  }

  makeCustomerIdentificationOptional() {
    const customerType = this.customer_type;
    LEGITIMATION_DOCUMENTS_STEP_IDENTIFIERS.forEach((uid) => {

      const step = this.getStep(uid)
      if (!step) {
        return
      }

      const memberIndex = getMemberIndexFromStepUID(uid)
      const optional = isLegitimationQuestionsEmpty(customerType, step)

      step.question.forEach(question => {

        if (![buildQuestionUIDForMember("legitimation['rear_side']", memberIndex, customerType),
              buildQuestionUIDForMember("legitimation['GUID']", memberIndex, customerType),
              buildQuestionUIDForMember("legitimation_is_valid", memberIndex, customerType)].includes(question.uid)) {

          question.optional = optional
          if (question.hasOwnProperty('question') && question.question.length) {
            question.question.forEach(subQuestion => {
              subQuestion.optional = optional
            })
          }

        }

      })

    })

  }

  makeCustomerReferenceOptional() {
    const callback = (question) => {
      question.optional = true;
      if (question.hasOwnProperty('question') && question.question.length) {
        question.question.forEach(subQuestion => {
          subQuestion.optional = true
        })
      }
    };

    this.__handleStepQuestions(REF_ACCOUNT_STEP_ID, callback);
    this.__handleStepQuestions(ORIGIN_OF_ASSETS_UID, callback)

  }

  /**
   * Go through questions of specific step and execute callback function for each question.
   *
   * @param stepIdentifier Step UID
   * @param callback Calllback function, that should be executed for question. Question is only argument.
   * @private
   */
  __handleStepQuestions(stepIdentifier, callback) {

    let step = this.getStep(stepIdentifier);
    if (!step) return
    step.question.forEach(callback)

  }

  /**
   * Remove values from questions answers for Identification step.
   *
   * @param questionsToSkip List of questions identifiers, that should be ignored.
   *                        Also will be set, as read only questions
   */
  cleanIdentificationQuestionsAnswers(step, questionsToSkip=[]) {

    const memberIndex = getMemberIndexFromStepUID(getUID(step.uid))

    this.__handleStepQuestions(buildStepUIDForMember(LEGITIMATION_STEP_ID, memberIndex), (question) => {

      question.optional = true

      if (!questionsToSkip.includes(question.uid)) {
        question.answer = null
      }
    })

  }

  setClearingAccountQuestionOptions() {

    const __transactionsExists = (transactions) => transactions && !_.isEmpty(transactions)

    const __portfolioSingleBuyOnly = (portfolio) => {

      return _.every([
        __transactionsExists(portfolio.transactions[TRANSACTION_TYPE_VALUES.BUY]),
        !__transactionsExists(portfolio.transactions[TRANSACTION_TYPE_VALUES.SELL]),
        !__transactionsExists(portfolio.transactions[TRANSACTION_TYPE_VALUES.SWITCH]),
        !__transactionsExists(portfolio.transactions[TRANSACTION_TYPE_VALUES.SAVINGS_PLAN]),
        !__transactionsExists(portfolio.transactions[TRANSACTION_TYPE_VALUES.PAYOUT_PLAN])])
    };

    const step = this.getStep(REF_ACCOUNT_STEP_ID)

    const clearingAccountQuestion = _.find(step.question,
      (question) => question.uid === CLEARING_ACCOUNT_QUESTION_UID);
    const clearingAccountOptionQuestion = _.find(step.question,
      (question) => question.uid === CLEARING_ACCOUNT_OPTION_QUESTION_UID);
    const clearingAccountBalancingQuestion = _.find(step.question,
      (question) => question.uid === CLEARING_ACCOUNT_BALANCING_QUESTION_UID);

    if (!clearingAccountQuestion || !clearingAccountBalancingQuestion || !clearingAccountOptionQuestion) {
      return
    }

    this.portfolios.forEach(portfolio => {
      if (!_.isEmpty(portfolio.clearing_account) && (portfolio.orderClearingAccountAllowed || portfolio.orderClearingAccountToBankAccountAllowed)) {

        let portfolioClearingAccountQuestion = buildPortfolioClearingAccountQuestion(
          portfolio, !portfolio.orderClearingAccountAllowed);
        let portfolioClearingAccountOptionQuestion = buildPortfolioClearingAccountOptionQuestion(
          portfolio, !portfolio.orderClearingAccountAllowed, getTransactionsTotalAmount(portfolio.transactions));

        // If at least one clearing account has not enough money - create balancing confirmation question
        if (_.some(Object.values(portfolio.clearing_account), (cAccount) => cAccount.value < getTransactionsTotalAmount(portfolio.transactions))) {
          if (portfolio.orderClearingAccountAllowed && portfolio.clearingAccountBalancingAllowed && __portfolioSingleBuyOnly(portfolio)) {
            clearingAccountBalancingQuestion.question.push(buildPortfolioClearingAccountBalancingQuestion(portfolio))
          }
        }

        clearingAccountQuestion.question.push(portfolioClearingAccountQuestion);
        clearingAccountOptionQuestion.question.push(portfolioClearingAccountOptionQuestion);
      }
    });

  }

  /**
   * Function to make all preparation for Customer Identification step in case Identification document is outdated.
   */
  validateOutdatedIdentification(step, customerType) {

    const memberIndex = getMemberIndexFromStepUID(getUID(step.uid))
    this.cleanIdentificationQuestionsAnswers(step,
      [
        buildQuestionUIDForMember("legitimation['expiry_date']", memberIndex, customerType),
        buildQuestionUIDForMember("legitimation_is_valid", memberIndex, customerType)])

    // Making expiry date as optional field with valid dates in past
    this.__handleStepQuestions(buildStepUIDForMember(LEGITIMATION_STEP_ID, memberIndex), (question) => {

      if (question.uid == buildQuestionUIDForMember("legitimation['expiry_date']", memberIndex, customerType)) {
        question.disabled = true
        question.disable_past = false
      }

    })
  }

  validateBankAccountRequirements() {
    const step = this.getStep(REF_ACCOUNT_STEP_ID);
    const clearingAccountQuestion = _.find(step.question, (q) => q.uid === 'clearing_account');

    const allPortfoliosWithClearingAccounts = this.portfolios.length === clearingAccountQuestion.question.length;
    const allClearingAccountsUsed = !_.find(clearingAccountQuestion.question, q => !q.answer);

    const bankAccountQuestion = _.find(step.question, q => q.uid === 'bank_account');
    if (bankAccountQuestion) {
      bankAccountQuestion.disabled = allPortfoliosWithClearingAccounts && allClearingAccountsUsed;
      bankAccountQuestion.optional = bankAccountQuestion.disabled;
    }
  }

  validateLegitimationConfirmationQuestion = (step) => {

    const memberIndex = getMemberIndexFromStepUID(getUID(step.uid))

    if (!this.skipLegitimationUsed(step)) {
      return
    }

    _.filter(step.question, (question) =>
      ![buildQuestionUIDForMember("legitimation_is_valid", memberIndex, this.customer_type),
        buildQuestionUIDForMember("legitimation['GUID']", memberIndex, this.customer_type)].includes(question.uid)
    ).forEach((question) => {
      question.optional = true
      question.disabled = true
    })

  }

  skipLegitimationUsed = (step) => {

    const memberIndex = getMemberIndexFromStepUID(getUID(step.uid))

    const legitimationConfirmationQuestion = _.find(
      step.question, (q) => q.uid == buildQuestionUIDForMember("legitimation_is_valid", memberIndex, this.customer_type))

    return legitimationConfirmationQuestion && legitimationConfirmationQuestion.answer
  }

  async __setQuestionnaire(onboardingAnswers) {

    await super.__setQuestionnaire(onboardingAnswers)

    this.setClearingAccountQuestionOptions()
    // Call this function one more time to fill clearing account
    // question with answers, as this question options are dynamic
    this._setDefaultAnswers(onboardingAnswers)
    this.validateBankAccountRequirements()

    // as isSwitchSellFlow - is getter function call it once here
    const isSellSwitch = this.isSwitchSellFlow;

    // for switch/sell flow -> make omitted questions optional
    if (isSellSwitch) {
      this.makeCustomerReferenceOptional();
      this.makeContactsInformationOptional();
      this.cleanReferenceOptions()
    }

    LEGITIMATION_DOCUMENTS_STEP_IDENTIFIERS.forEach((uid => {
      const identificationStep = this.getStep(uid);

      if (!identificationStep) {
        return
      }

      const skipLegitimationUsed = this.skipLegitimationUsed(identificationStep)

      const legitimationOutdated = isLegitimationQuestionOutdated(identificationStep, this.customer_type)

      if (legitimationOutdated) {
        console.info("Legitimation Outdated!")

        // this._isIdentificationOutdated = true
        identificationStep.isIdentificationOutdated = true

        this.validateOutdatedIdentification(identificationStep, this.customer_type)
      } else if (isSellSwitch || skipLegitimationUsed) {
        this.makeCustomerIdentificationOptional()

        if (skipLegitimationUsed) {
          const memberIndex = getMemberIndexFromStepUID(getUID(identificationStep.uid))
          this.cleanIdentificationQuestionsAnswers(identificationStep,
            [buildQuestionUIDForMember("legitimation_is_valid", memberIndex, this.customer_type)])
        }
      }

      this.validateLegitimationConfirmationQuestion(identificationStep)

    }))
  }

  getDataForAccount() {
    const data = super.getDataForAccount();

    const step = this.getStep(LEGITIMATION_STEP_ID);
    if (step && isLegitimationQuestionsEmpty(this.customer_type, step)) {
      delete data.legitimation; // to not send "empty" legitimation data
    }

    return {
      client_details: data,
      portfolios: this.portfolios.reduce((result, portfolio) => {
        result[portfolio.portfolioId] = portfolio;
        return result;
      }, {}),
    };
  }
}
