import clsx from "clsx";
import React from "react";

import {
  useParams
} from "react-router-dom";

import { connect } from 'react-redux';

import withStyles from "@material-ui/core/styles/withStyles";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from "@material-ui/core/Grid";
import {Skeleton} from "@material-ui/lab";

import PrimaryButton from "../../../../../../../components/Buttons/PrimaryButton";
import StepFooter from "../../StepFooter";
import SliderFormElement from "../../formElement/SliderFormElement";
import styles from "../styles";
import ConfirmModal from "../../../../../../../components/ConfirmModal";
import {QuestionnairesHandlerResource} from "../../../../../../../utils/api";
import { downloadPdf } from "../../../../../../../utils/utils";
import { setProductSelectionSRRI } from '../../../../../actions'
import { displayErrorSnackBar } from '../../../../../../../components/SnackbarProvider/actions'
import {getDanteCustomerFullName} from "../../../../../utils";


const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
});

export const getSRRICategory = (srri) => {
  const low = [1,2];
  const medium = [3,4,5];
  const high = [6,7];

  if (low.includes(srri)) return "Defensiv";
  else if (medium.includes(srri)) return "Moderat";
  else if (high.includes(srri)) return "Offensiv";
};

const FinalStep = props => {
  const {
    classes,
    uid,
    name,
    help_text,
    questions,
    loading,
    onPrevClick,
    onResendResultClick,
    onConfirmResultClick,
    onAnswerChange,
    dataService,
  } = props;

  let { customer_id } = useParams();

  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [srriTexts, setSrriTexts] = React.useState({});
  const [questionnaireFileDownloadingProgress, setQuestionnaireFileDownloadingProgress] = React.useState(false)

  const question = questions[0];

  const values = [1, 2, 3, 4, 5, 6, 7];
  const marks = values.map((value, index) => ({
    value: 100 / (values.length - 1) * index,
    label: value
  }));
  const step = 100 / (values.length - 1);
  const min = 0;
  const max = (question.value - 1) * step || 0.1; // workaround for max = 0 to prevent slider width = 0
  const normal_value = typeof question.answer === 'number' ? question.answer : question.value;

  props.dispatch(setProductSelectionSRRI(normal_value));

  const value = (normal_value - 1) * step;
  const sliderStyle = {
    position: 'absolute',
    left: `${min}%`,
    right: `${100 - max}%`,
    top: 0,
    width: 'auto'
  };

  let category = getSRRICategory(normal_value);

  const categoryForSubtitle = {
    "Moderat": "moderate"
  };

  const getSelectedMark = (value) => {
    return marks && marks.find(item => item.value === value);
  };

  const handleChange = (value) => {
    const mark = getSelectedMark(value);
    if(mark){
      const answer = mark.value / step + 1;
      onAnswerChange(question.uid, answer);
    }
  };

  const handleConfirmClick = () => {
    onConfirmResultClick({suggested_srri: question.value, srri: normal_value})
    props.dispatch(setProductSelectionSRRI(normal_value))
  };

  const handlePdfDownload = () => {

    let requestBody = {
      'customer_id': customer_id,
      'questionnaire_session_id': dataService.session_id,
    }

    setQuestionnaireFileDownloadingProgress(true)

    downloadPdf(`/reports/onboarding/questionnaire-pdf/`, undefined, 'POST', undefined, requestBody)
    .then(() => setQuestionnaireFileDownloadingProgress(false))
    .catch(error => {
      setQuestionnaireFileDownloadingProgress(false)
      props.dispatch(displayErrorSnackBar("Fehler bei der PDF-Erstellung"))
    });
  };

  React.useEffect(() => {
    if (!question.answer) {
      onAnswerChange(question.uid, normal_value);
    }
    QuestionnairesHandlerResource.at('get-srri-texts/').get().then(res => setSrriTexts(res));
  }, []);

  const BackButton = (
    <PrimaryButton
      text={"Zurück"}
      icon={<ArrowBack color={'primary'}/>}
      variant={"outlined"}
      onButtonClick={onPrevClick}
    />
  );

  const ResendResultButton = (
    <PrimaryButton
      text={"Risikoprofil neu ermitteln"}
      variant={"outlined"}
      onButtonClick={() => setConfirmOpen(true)}
    />
  );

  const ConfirmResultButton = (
    <PrimaryButton
      text={"Bestätigen"}
      onButtonClick={handleConfirmClick}
    />
  );

  const ConfirmResetTitle = (
    <span style={{paddingRight: 150}}>
      <i className={'fa fa-exclamation-circle'}
         style={{color: '#f50702', verticalAlign: 'middle', marginRight: 10, fontSize: 36}} aria-hidden="true" />
      Sind Sie sicher?
    </span>
  );

  return (
    <div className={classes.stepContainer}>
      <div className={classes.stepScrollContainer}>
        <div className={clsx(classes.stepContent, 'app-page-container')}>
          <div className={classes.stepName} data-id={`${uid}-name`}>
            {name.replace('{customer_name}', getDanteCustomerFullName(dataService.current_customer)).replace('{risk_category}', category)}
          </div>
          {help_text && (
            <div className={classes.stepHelpText} style={{
              textAlign: 'center',
            }} data-id={`${uid}-help`}>
              {help_text.replace('{risk_category}', (categoryForSubtitle[category] || category).toLowerCase())}
            </div>
          )}

          <div className={classes.finalContent}>
            <div>
              <h3>Risikoklasse: {normal_value}</h3>
              <div className={classes.stepInfoText} style={{
                fontFamily: 'Roboto-Regular',
                marginBottom: 80
              }}>
                {question.info && (
                  <span>
                    <span className='far fa-info-circle' />
                    &nbsp;
                    {question.info}
                  </span>
                )}
              </div>
              <div style={{position: 'relative', height: 60}}>
                <SliderFormElement
                  disabled={question.disabled}
                  value={value}
                  step={step}
                  marks={marks}
                  min={min}
                  max={max}
                  onChange={handleChange}
                  style={sliderStyle}
                  isRiskStep={true}
                />
              </div>
              <div className={classes.finalSliderLabels}>
                <div className={clsx(category === "Defensiv" && classes.finalActiveLabel)}><span>Defensiv:</span><br/><span>1 - 2</span></div>
                <div className={clsx(category === "Moderat" && classes.finalActiveLabel)}><span>Moderat:</span><br/><span>3 - 5</span></div>
                <div className={clsx(category === "Offensiv" && classes.finalActiveLabel)}><span>Offensiv:</span><br/><span>6 - 7</span></div>
              </div>
            </div>

            <Grid container spacing={2} style={{fontSize: 16}}>
              <Grid item xs={12} className={classes.finalPdfButtonWrap}>
                <PrimaryButton
                  text={"Risikoprofil als PDF herunterladen"}
                  icon={questionnaireFileDownloadingProgress ? <CircularProgress style={{color: 'rgba(0, 0, 0, 0.26)'}} size={15}/> : <SaveAltIcon />}
                  onButtonClick={handlePdfDownload}
                />
              </Grid>

              <Grid item xs={12}>
                Bei der Ermittlung des Risikoprofils greifen viele Faktoren ineinander und beeinflussen sich gegenseitig:
              </Grid>

              {srriTexts && (srriTexts[normal_value.toString()] || []).map((text, idx) => (
                <Grid item xs={12} key={idx}>
                  <div className={classes.finalListItem}>{text}</div>
                </Grid>
              ))}
              {!srriTexts && (
                <>
                  <Grid item xs={12}><Skeleton /></Grid>
                  <Grid item xs={12}><Skeleton /></Grid>
                  <Grid item xs={12}><Skeleton /></Grid>
                </>
              )}

              <Grid item xs={12}>
                Das Risikoprofil des/der Kunden/in wurde mit den Risikoeinstufungen der Produkte gemäß SRI
                gleichgesetzt, wobei 1 das niedrigste und 7 das höchste Risiko ausdrückt.
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.scrollFix} />
      </div>
      <ConfirmModal
        open={confirmOpen}
        handleClose={() => setConfirmOpen(false)}
        handleConfirm={onResendResultClick}
        title={ConfirmResetTitle}
        cancelBtnText={'Abbrechen'}
        confirmBtnText={'Löschen'}
      />

      <StepFooter
        leftItems={[BackButton]}
        rightItems={[ResendResultButton, ConfirmResultButton]}
      />
    </div>
  );
};

export default connect(mapStateToProps)(withStyles(styles)(FinalStep));
