import React from 'react'
import moment from 'moment'
import _ from "lodash";

import {
  SellsStep,
  RiskStep,
  ClientStep,
  DocumentsDownload
} from '../StepContent/components/step/FinalizeSteps'
import {
  radioYesUID,
  radioNoUID,
  FIELD_REQUIRED_MSG,
  FIELD_DOES_NOT_FITT_MSG,
} from '../../constants'
import {isRequiredQuestion, validateMultilineContent} from '../../utils'
import {buildQuestionUIDForMember} from "../../service";
import DateAndPlaceStep
  from "../StepContent/components/step/FinalizeSteps/ConversationDetailsStep/SubSteps/DateAndPlaceStep";
import ParticipantsStep
  from "../StepContent/components/step/FinalizeSteps/ConversationDetailsStep/SubSteps/ParticipantsStep";


const finilizeSellsStep = {
  "uid": "finalize-sells",
  "name": "Bitte auswählen",
  "behaviour": ["dynamic", "Verkaufsunterlagen"],
  "step_content": SellsStep,
  "question": [
    {
      "uid": "main_information_provided['checkbox']",
      "type": "checkbox",
      "answer": true,
      "question_text": "Aktuelle Produktinformationen wurden zur Verfügung gestellt.",  // Current product information has been made available to the customer.
      "optional": false,
    },
    {
      "uid": "yearly_report_information_provided['checkbox']",
      "type": "checkbox",
      "question_text": <span>
        <b>Der Verkaufsprospekt sowie der letzte veröffentlichte Jahres- und Halbjahresbericht wurden kostenlos zur Verfügung gestellt.</b><br/>
        Das Zurverfügungstellen dieser Dokumente ist verpflichtend, wenn Alternative Investmentfonds zum Erwerb empfohlen wurden.
      </span>,
      "optional": true,
    }
  ],
};

const conflictsStep = {
  "uid": "finalize-sells-conflicts",
  "name": "Der Kunde/die Kundin wurde auf eventuelle Interessenkonflikte hingewiesen bzw. wurden entsprechende Hinweise ausgehändigt.",
  "behaviour": ["dynamic", "Interessenkonflikte"],
  "step_content": SellsStep,
  "question": [
    {
      "uid": "conflicts",
      "question_text": "",
      "info_text": "Sind Sie sich sicher, dass Sie nicht auf Interessenkonflikte hingewiesen haben? Dies kann zu Beanstandungen bei einer Prüfung durch den Wirtschaftsprüfer führen. Es wird empfohlen, die Offenlegung der Interessenkonflikte bei allen Kunden vorzunehmen und dies in jeder Beratung zu dokumentieren. Die Offenlegung kann mittels Übergabe der Kundenerstinformation erfolgen.",
      "type": "normal",
      "behaviour": ["radio"],
      "config": {
        "choices": [
          {
            "id": 1,
            "uid": radioYesUID,
            "text": "Ja"
          },
          {
            "id": 2,
            "uid": radioNoUID,
            "text": "Nein"
          }
        ]
      },
      "optional": false,
      "custom_classes": {"labelRoot": "bold"},
      "isDisplayInfo": function () {
        return this.answer && this.answer === radioNoUID;
      }
    },
    {
      "uid": "conflicts_details",
      "question_text": "Angaben zu Interessenkonflikten oder zusätzliche Bemerkungen",
      "type": "input",
      "optional": true,
      "custom_classes": {"labelRoot": "bold"},
      "config": {
        "placeholder": "Mehr über die Interessekonflikte oder zusätzliche Bemerkungen"
      },
      "validateImmediately": true,
      "isValid": function(step=undefined) {
        if (step) {
          if (this.answer) {
            let isValid = validateMultilineContent(this.answer, 770)
            this.error = isValid ? null : FIELD_DOES_NOT_FITT_MSG
            return isValid
          }
        }
        this.error = null
        return true
      }
    }
  ]
};

const step2 = {
  "uid": "finalize-risk",
  "name": 'Aufklärung über Risiken',
  "behaviour": ["dynamic", "Risiken"],
  "step_content": RiskStep,
  "question": [
    {
      "uid": "risk",
      "type": "checkbox",
      "question_text": "Der Kunde/die Kundin wurde über die mit der Investitionsentscheidung in Verbindung stehenden " +
        "Risiken aufgeklärt. Insbesondere wurde der Kunde/die Kundin darauf hingewiesen, dass das Risiko eines " +
        "Totalverlustes der investierten Vermögenswerte besteht.",
      "optional": false,
    },
    {
      "uid": "risk_credit",
      "type": "checkbox",
      "question_text": "Bonitätsrisiko",
    },
    {
      "uid": "risk_emittent",
      "type": "checkbox",
      "question_text": "Emittentenrisiko",
    },
    {
      "uid": "risk_termination",
      "type": "checkbox",
      "question_text": "Kündigungsrisiko",
    },
    {
      "uid": "risk_economic",
      "type": "checkbox",
      "question_text": "Konjunkturrisiko",
    },
    {
      "uid": "risk_exchange_rate",
      "type": "checkbox",
      "question_text": "Kursänderungsrisiko",
    },
    {
      "uid": "risk_liquidity",
      "type": "checkbox",
      "question_text": "Liquiditätsrisiko",
    },
    {
      "uid": "risk_suspension_redemption",
      "type": "checkbox",
      "question_text": "Risiko der Aussetzung der Anteilsrücknahme",
    },
    {
      "uid": "risk_currency",
      "type": "checkbox",
      "question_text": "Währungsrisiko",
    },
    {
      "uid": "risk_interest_rate",
      "type": "checkbox",
      "question_text": "Zinsänderungsrisiko",
    },
    {
      "uid": "risk_additional",
      "type": "checkbox",
      "question_text": "Weitere Risiken:",
    },
    {
      "uid": "risk_additional_details",
      "type": "input",
      "config": {
        "multiline": true,
        "placeholder": "Bitte schreiben Sie hier"
      },
      "validateImmediately": true,
      "isValid": function(step, afterChange) {
        let isValid = true
        // validate required ONLY on button click

        if(!afterChange && isRequiredQuestion(this)) {
          isValid = !!this.answer;
          this.error = isValid ? null : FIELD_REQUIRED_MSG;
        } else if (this.answer) {
          isValid = validateMultilineContent(this.answer, 340)
          this.error = isValid ? null : FIELD_DOES_NOT_FITT_MSG
        }

        return isValid
      }
    }
  ]
}

const step3 = {
  "uid": "finalize-client",
  "name": "Sind die Kundenangaben aktuell? *",
  "behaviour": ["dynamic", "Kundenangabe"],
  "step_content": ClientStep,
  "question": [
    {
      "uid": "data_type",
      "type": "normal",
      "behaviour": ["radio"],
      "optional": false,
      "question_text": '',
      "config": {
        "choices": [
          {
            "id": 1,
            "uid": "1",
            "text": "Die Angaben der Kunden / Bevollmächtigten / gesetzl. Vertreters vom <data_type_date> sind noch aktuell und bilden die Basis für dieses Beratungsgespräch.",
          },
          {
            "id": 2,
            "uid": "2",
            "text": "Die Kundenangaben wurden neu erhoben und bilden die Basis für dieses Beratungsgespräch.Die Angaben werden den Kunden / Bevollmächtigten / gesetzl. Vertreter zusammen mit diesem Protokoll ausgehändigt bzw. übersandt."
          }
        ]
      },
      "isValid": function (){
        const isValid = !!this.answer;
        this.error = isValid ? null : FIELD_REQUIRED_MSG;
        return isValid;
      },
      "custom_classes": {
        radioGroupRoot: 'finalizeOnboardingConversationDetailsRadio',
        formControlLabelRoot: 'finalizeOnboardingFormControlLabelRoot',
        "labelRoot": "bold"
      }
    }, {
      "uid": "data_type_date",
      "type": "date",
      "isValid": function (step=undefined) {
        if (step) {
          if (step.question[0].answer == 1) {
            const isValid = !!this.answer;
            this.error = isValid ? null : FIELD_REQUIRED_MSG;
            return isValid;
          }
        }
        return true
      }
    }
  ]
}

const CONVERSATION_DETAILS_GROUP = 'Angaben zum Gespräch';
const DOCUMENTS_GROUP = 'Dokumente';
const INTERVIEW_CUSTOMER_INPUT_UID = "interview_customer_input";
export const INTERVIEW_PLACE_CUSTOMER_INPUT_UID = "interview_place_customer_input";
export const ACCOUNT_HOLDER_1_UID = buildQuestionUIDForMember(INTERVIEW_CUSTOMER_INPUT_UID, 0);
export const ACCOUNT_HOLDER_2_UID = buildQuestionUIDForMember(INTERVIEW_CUSTOMER_INPUT_UID, 1);
export const INTERVIEW_PLACE_ACCOUNT_HOLDER_1_UID = buildQuestionUIDForMember(
  INTERVIEW_PLACE_CUSTOMER_INPUT_UID, 0);
export const INTERVIEW_PLACE_ACCOUNT_HOLDER_2_UID = buildQuestionUIDForMember(
  INTERVIEW_PLACE_CUSTOMER_INPUT_UID, 1);

export const INTERVIEW_PLACE = {
  ONLINE: 1,
  BROKER: 2,
  CUSTOMER: 3,
  OTHER: 4
}

const dateAndPlaceStep = {
  "uid": "finalize-conversation-details-date-place",
  "name": "Wann fand das Gespräch statt?",
  "parent": CONVERSATION_DETAILS_GROUP,
  "behaviour": ["dynamic", "Datum & Ort"],
  "step_content": DateAndPlaceStep,
  "question": [
    {
      "uid": "interview_date",
      "question_text": "Gesprächsdatum",
      "type": "date",
      "optional": false,
      "custom_classes": {
        "labelRoot": "regular",
      },
      "disableDatesCallback": function(){
        return false; // all dates available
      }
    },
    {
      "uid": "interview_start_time",
      "question_text": "Gesprächsbeginn",
      "type": "time",
      "optional": false,
      "custom_classes": {
        "labelRoot": "regular",
      },
    },
    {
      "uid": "interview_end_time",
      "question_text": "Gesprächsende",
      "optional": false,
      "type": "time",
      "custom_classes": {
        "labelRoot": "regular",
      },
      "isValid": function (step) {
        if (step) {
          if (step.question[1].answer && this.answer) {
            const isValid = moment(step.question[1].answer) < moment(this.answer);
            this.error = isValid ? null : 'Sollte nach der "' + step.question[1].question_text + '" sein';
            return isValid;
          }
        }
        const isValid = !!this.answer;
        this.error = isValid ? null : FIELD_REQUIRED_MSG;
        return isValid
      }
    },
    {
      "uid": "interview_place",
      "question_text": "Wo fand das Gespräch statt?",
      "type": "normal",
      "behaviour": ["radio"],
      "custom_classes": {
        "labelRoot": "bold", "formControlLabelRoot": "noMarginBottom", "radioGroupRoot": "noMarginBottom"
      },
      "config": {
        "choices": [
          {
            "id": INTERVIEW_PLACE.ONLINE,
            "uid": INTERVIEW_PLACE.ONLINE.toString(),
            "text": "Telefonisch bzw. Internet/Videoanruf",
            "info_text": "Haben Sie das Gespräch aufgezeichnet?",
          },
          {
            "id": INTERVIEW_PLACE.BROKER,
            "uid": INTERVIEW_PLACE.BROKER.toString(),
            "text": "Firmensitz / beim Berater",
          },
          {
            "id": INTERVIEW_PLACE.CUSTOMER,
            "uid": INTERVIEW_PLACE.CUSTOMER.toString(),
            "text": "Beim Kunden",
          },
          {
            "id": INTERVIEW_PLACE.OTHER,
            "uid": INTERVIEW_PLACE.OTHER.toString(),
            "text": "Sonstiger Ort",
          },
        ]
      },
      "optional": false
    },
    {
      "uid": "interview_place_broker_input",
      "question_text": "Ort des Vermittlers",
      "type": "input",
      "optional": false,
      "custom_classes": {"labelRoot": "bold", "inputRoot": "noMarginBottom"},
    },
    {
      "uid": INTERVIEW_PLACE_CUSTOMER_INPUT_UID,
      "question_text": "Ort des Kunden",
      "type": "input",
      "custom_classes": {"labelRoot": "bold", "inputRoot": "noMarginBottom"},
    },
    {
      "uid": INTERVIEW_PLACE_ACCOUNT_HOLDER_1_UID,
      "question_text": "Kunde 1",
      "type": "input",
      "custom_classes": {"labelRoot": "bold", "inputRoot": "noMarginBottom"},
    },
    {
      "uid": INTERVIEW_PLACE_ACCOUNT_HOLDER_2_UID,
      "question_text": "Kunde 2",
      "type": "input",
      "custom_classes": {"labelRoot": "bold", "inputRoot": "noMarginBottom"},
    },
  ]
};

const participantsStep = {
  "uid": "finalize-conversation-details-participants",
  "name": "Wer hat an dem Gespräch teilgenommen? *",
  "behaviour": ["dynamic", "Gesprächsteilnehmer"],
  "parent": CONVERSATION_DETAILS_GROUP,
  "step_content": ParticipantsStep,
  "question": [
    {
      "uid": "interview_broker_input",
      "question_text": "Vermittler/-in",
      "type": "input",
      "optional": false,
      "custom_classes": {"labelRoot": "bold", "inputRoot": "noMarginBottom"},
    },
    {
      "uid": INTERVIEW_CUSTOMER_INPUT_UID,
      "question_text": "Kunde/-in",
      "type": "input",
      "custom_classes": {"labelRoot": "bold", "inputRoot": "noMarginBottom"},
    },
    {
      "uid": ACCOUNT_HOLDER_1_UID,
      "question_text": "Kunde 1",
      "type": "input",
      "custom_classes": {"labelRoot": "bold", "inputRoot": "noMarginBottom"},
    },
    {
      "uid": ACCOUNT_HOLDER_2_UID,
      "question_text": "Kunde 2",
      "type": "input",
      "custom_classes": {"labelRoot": "bold", "inputRoot": "noMarginBottom"},
    },
    {
      "uid": "interview_other_peoples_input",
      "type": "input",
      "question_text": "Weitere Gesprächsteilnehmer",
      "validateImmediately": true,
      "isValid": function(step, afterChange) {
        let isValid = true;

        if (this.answer) {
          isValid = validateMultilineContent(this.answer, 28, false)
          this.error = isValid ? null : FIELD_DOES_NOT_FITT_MSG
        }

        return isValid
      }
    },
    {
      "uid": "interview_date_type",
      "type": "normal",
      "question_text": "Es handelt sich um:",
      "behaviour": ["radio"],
      "config": {
        "choices": [
          {
            "id": 1,
            "uid": "1",
            "text": "Es ist das Erstgespräch",
          },
          {
            "id": 2,
            "uid": "2",
            "text": "Es ist ein Folgetermin zum Gespräch vom:"
          }
        ]
      },
      "optional": false,
      "isValid": function (){
        const isValid = !!this.answer;
        this.error = isValid ? null : FIELD_REQUIRED_MSG;
        return isValid;
      },
      "custom_classes": {
        "labelRoot": "bold",
        radioGroupRoot: 'finalizeOnboardingConversationDetailsRadio'
      }
    },
    {
      "uid": "interview_repeated_date",
      "type": "date",
      "disableDatesCallback": function(){
        return false; // all dates available
      },
      "isValid": function (step=undefined) {

        if (step) {
          if (step.question.find(q => q.uid === 'interview_date_type').answer == 2) {
            const isValid = !!this.answer;
            this.error = isValid ? null : FIELD_REQUIRED_MSG;
            return isValid;
          }
        }

        return true
      }
    }
  ]
};

const sustainabilityStep = {
  "uid": "finalize-sustainability-check",
  "name": "Wird mit dem Kunden/der Kundin eine dauerhafte Geeignetheitserklärung vereinbart? *",
  "behaviour": ["dynamic", "Geeignetheitsprüfung"],
  "parent": CONVERSATION_DETAILS_GROUP,
  // "step_content": ConversationDetailsStep,
  "question": [
    {
      "uid": "permanent_suitability_check",
      "size": 6,
      "optional": false,
      // "question_text": 'Vereinbarung einer dauerhaften Geeignetheitsprüfung',
      "type": "normal",
      "behaviour": ["radio"],
      "custom_classes": {
        "labelRoot": "bold",
      },
      "config": {
        "choices": [
          {
            "id": 1,
            "uid": radioYesUID,
            "text": <><b>Ja</b>, eine dauerhafte Geeignetheitsprüfung wird vereinbart.</>
          },
          {
            "id": 2,
            "uid": radioNoUID,
            "text": <><b>Nein</b>, es wird keine dauerhafte Geeignetheitsprüfung vereinbart.</>
          }
        ]
      },
    }
  ]
};

const initiatorStep = {
  "uid": "finalize-conversation-details-initiator",
  "name": "Von wem ging die Gesprächsinitiative aus? *",
  "behaviour": ["dynamic", "Gesprächsinitiative"],
  "parent": CONVERSATION_DETAILS_GROUP,
  // "step_content": ConversationDetailsStep,
  "question": [
    {
      "uid": "interview_initiative",
      // "question_text": "Gesprächsinitiative",
      "type": "text",
      "optional": false,
      "custom_classes": {
        "labelRoot": "bold",
      },
    },
  ]
};

const reasonStep = {
  "uid": "finalize-conversation-details-reason",
  "name": "Individuelle Begründung der Anlageentscheidung",
  "behaviour": ["dynamic", "Begründung"],
  "parent": CONVERSATION_DETAILS_GROUP,
  // "step_content": ConversationDetailsStep,
  "question": [
    {
      "uid": "interview_reasons",
      // "question_text": "Individuelle Begründung der Anlageentscheidung",
      "type": "text",
      "optional": true,
      "custom_classes": {
        "labelRoot": "bold",
      },
    },
  ]
};

const documentsDelivery = {
  "uid": "finalize-documents-delivery",
  "name": "Wie werden das Protokoll und die zugehörigen Anlagen inkl. Ex-ante Kosten Ausweis zugestellt? *",
  "behaviour": ["dynamic", "Zustellung"],
  "parent": DOCUMENTS_GROUP,
  "question": [
    {
      "uid": "ways_of_distribution",
      "info_text": "Zusätzlich zu den gewählten Zustelloptionen werden alle Dokumente in das Online-Postfach eingestellt.",
      "optional": false,
      "question": [
        {
          "uid": "ways_of_distribution['electronically']",
          "type": "checkbox",
          "question_text": "Die Dokumente werden auf einem dauerhaften Datenträger elektronisch übermittelt (PDF / E-Mail)",
        }, {
          "uid": "ways_of_distribution['end_of_consultation']",
          "type": "checkbox",
          "question_text": "Die Dokumente werden am Ende der Beratung übergeben",
        }, {
          "uid": "ways_of_distribution['by_post']",
          "type": "checkbox",
          "question_text": "Die Dokumente werden per Post zugesandt",
        }
      ],
      "isValid": function(){
        if (!this.optional) {
          const isValid = this.question.some(q => q.answer);
          this.error = isValid ? null : FIELD_REQUIRED_MSG;
          return isValid;
        }
        return true
      }
    }
  ]
};

const step6 = {
  "uid": "finalize-documents-downloading",
  "loading_msg": 'Bitte warten Sie einen Moment. Ihre ausgewählten Dokumente werden erzeugt.',
  "name": "Auswahl der Dokumente",
  "parent": DOCUMENTS_GROUP,
  "behaviour": ["dynamic", "Auswahl der Dokumente"],
  "step_content": DocumentsDownload,
  "question": [
    {
      "uid": "documents",
      "type": "input",
      "isValid": function () {

        const documentsToDownload = _.get(this.answer || {}, 'download') || []
        const documentsToSign = _.get(this.answer || {}, 'eSign') || []

        this.errors = null
        const errors = {}

        Object.entries(this.documents || {}).forEach(([object_type, depots]) => {
          Object.keys(depots).forEach((depot_id) => {
            this.documents[object_type][depot_id].documentsToValidate.forEach((document) => {

              const isDocumentSelected = _.get(documentsToDownload, `${object_type}.${depot_id}`, []).includes(document.id) || _.get(documentsToSign, `${object_type}.${depot_id}`, []).includes(document.id)
              if (document.is_mandatory && !isDocumentSelected) {

                if (!errors.hasOwnProperty(object_type)) {
                  errors[object_type] = {}
                }

                if (!errors[object_type].hasOwnProperty(depot_id)) {
                  errors[object_type][depot_id] = {}
                }

                errors[object_type][depot_id][document.id] = 'Dokument ist obligatorisch'
              }

            })
          })

        })

        if (!_.isEmpty(errors)) {
          this.errors = errors
          return false
        }

        return true
      }
    }
  ]
};

const congratsStep = {
  "uid": "congrats-step",
  "name": "Aufklärung & Protokoll",
  "successBody": "Die Kundendaten wurde erfolgreich gespeichert. Fahren Sie fort mit Aufklärung & Protokoll um den prozess erfolgreich abschließen zu können.",
  "behaviour": ["congrats"]
};


export const protocolStepsData = {
  steps: [
    finilizeSellsStep,
    conflictsStep,
    step2,
    step3,
    dateAndPlaceStep,
    participantsStep,
    sustainabilityStep,
    initiatorStep,
    reasonStep,
    documentsDelivery,
    step6,
    congratsStep
  ],
};