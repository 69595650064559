/**
 * @typedef CreateOrderResponseError
 * @type {Object}
 * @property {{depotNumber: String, depotName: String, depotCategory: String}} portfolio Portfolio, that error was triggered for
 * @property {Object} error Error details
 */

/**
 * @typedef CreateOrderResponseErrorHandled
 * @type {Object}
 * @property {{[depotNUmber: String]: {portfolioName: String, errors: Array<*>}}}
 */


import _ from "lodash";

import {buildQuestionUIDForMember, Service} from "./service";
import {buildDataSteps} from "./mock_kundendaten"
import {QuestionnairesHandlerResource} from "../../utils/api";
import {
  getDanteCustomerData,
  getDanteCustomerFullName,
  getLegitimationData,
  getLegitimationFileFormDataFromGuid,
  getMemberFieldName,
  getQuestionType,
  getStepAnswer,
  getUID,
  setQuestionAnswerFromConfig,
  UpdateCustomerAndSync,
  UpdateCustomerData
} from "./utils";
import {
  buildStepUIDForMember,
  COUPLE_MEMBERS_CONFIRMATION_STEP_IDENTIFIERS,
  COUPLE_MEMBERS_OTHER_FIELDS, CUSTOMER_TYPES,
  LEGITIMATION_DOCUMENTS_STEP_IDENTIFIERS, LEGITIMATION_STEP_ID,
  QUESTION_TYPE, REF_ACCOUNT_STEP_ID,
  USER_CONFIRMATION_STEP_IDENTIFIER
} from "./constants";
import {getErrorMessage} from "../../utils/utils";
import {SERVICE_CONCEPTS} from "../Trades/constants";
import {getMemberIndexFromStepUID} from "./services";
import {bankAccountToOption} from "./components/StepContent/components/question/ReferenceAccountsQuestion/ReferenceAccountsQuestion";
import {SERVER_ERROR} from "../../utils/constants";

const getMainBankAccount = (bankAccounts) => {
  if (!bankAccounts) return undefined

  return _.find(bankAccounts, account => account.mainBankAccount)
}


export class CustomerService extends Service {

  async updateCustomerMemberData() {
    const memberIndex = getMemberIndexFromStepUID(getUID(this._step.uid))
    if (!_.isUndefined(memberIndex) && this.members[memberIndex]) {
      let customerData = await UpdateCustomerAndSync(this.members[memberIndex].id, this._step, undefined, undefined, this.isSwitchSellFlow);

      if (!COUPLE_MEMBERS_CONFIRMATION_STEP_IDENTIFIERS.includes(this._step.uid)) return

      if (customerData) {
        let userConfirmationStep = _.find(this._questionnaire.steps, (step) => step.uid === USER_CONFIRMATION_STEP_IDENTIFIER)
        if (!!userConfirmationStep) {
          let { data } = customerData
          this.members[memberIndex] = data

          let updatedData = {
            "relationships": this.members
          }

          this._questionnaire.steps.map((step) => {

            if (step.withUserName && getMemberIndexFromStepUID(step.uid) == getMemberIndexFromStepUID(this.step.uid)) {
              this.__updateStepNameWithCustomer(step, getDanteCustomerFullName(data));
            }

            (step.question || []).map((q) => {
              if (getUID(step.uid) === USER_CONFIRMATION_STEP_IDENTIFIER) {
                setQuestionAnswerFromConfig(q, updatedData);
              }
            })
          })

          await super.__sendStepData(false, userConfirmationStep)

          // await this.__sendStepAnswerData(userConfirmationStep)
        }
      }

    }
  }

  getDanteLegitimationGUID = (legitimation, documentType) => {
    return legitimation.identificationDocument && legitimation.identificationDocument[documentType] && legitimation.identificationDocument[documentType].toUpperCase()
  }

  /**
   * Send legitimation data from step.
   * @param {*} step - Step data
   * @param customerType
   * */
  async _sendUserLegitimationStepData(step, customerType) {

    let memberIndex = getMemberIndexFromStepUID(step.uid)

    const extraInfo = {};
    _.set(extraInfo, 'legitimation.0.economicBeneficiary',
      this.getStepAnswer(
        buildStepUIDForMember('A1', memberIndex),
        buildQuestionUIDForMember('Berechtigter', memberIndex, customerType)));
    _.set(extraInfo, 'legitimation.0.peP',
      this.getStepAnswer(
        buildStepUIDForMember('A1', memberIndex),
        buildQuestionUIDForMember('pep', memberIndex, customerType)));
    _.set(extraInfo, 'legitimation.0.fatca',
      this.getStepAnswer(
        buildStepUIDForMember('taxable', memberIndex),
        buildQuestionUIDForMember('usa_steuerpflichtig', memberIndex, customerType)));

    // All legitimations, that confirmed in investment app
    // should be used as main Legitimation document
    _.set(extraInfo, 'legitimation.0.main', true)

    //region Functionality to handle GUID in correct way,
    // if type and numebr already exists in crm - legitimation should be updated.
    // Otherwise - new legitimation should be created
    const legitimationStepUID = buildStepUIDForMember(LEGITIMATION_STEP_ID, memberIndex);

    let legitimationTypeQuestion = this.getQuestion(
      legitimationStepUID,
      buildQuestionUIDForMember("legitimation['type']", memberIndex, customerType));
    let legitimationNumber = this.getStepAnswer(
      legitimationStepUID,
      buildQuestionUIDForMember("legitimation['number']", memberIndex, customerType));

    let legitimationData = getLegitimationData(step.all_legitimations || [], legitimationTypeQuestion.answer, legitimationNumber, legitimationTypeQuestion.config.mapping.config)

    step.question.forEach(q => {
      // if legitimation with type + number already exists - update it, otherwise - create new
      if (q.uid == buildQuestionUIDForMember("legitimation['GUID']", memberIndex, customerType)) {
        q.answer = legitimationData ? legitimationData.GUID : null
      }

      if ([buildQuestionUIDForMember("legitimation['front_side']", memberIndex, customerType), buildQuestionUIDForMember("legitimation['rear_side']", memberIndex, customerType)].includes(q.uid) && legitimationNumber) {

        // Workaround to set formData as a file answer,
        // if new legitimation should be created,
        // but answer contains only guid

        const isFrontSideQuestion = q.answer && q.answer.guid && q.uid == buildQuestionUIDForMember("legitimation['front_side']", memberIndex, customerType)
        const isRearSideQuestion = q.answer && q.answer.guid && q.uid == buildQuestionUIDForMember("legitimation['rear_side']", memberIndex, customerType)

        if (!legitimationData ||
            (q.answer && q.answer.guid && isFrontSideQuestion && q.answer.guid.toUpperCase() != this.getDanteLegitimationGUID(legitimationData, 'Legitimation')) ||
            (q.answer && q.answer.guid && isRearSideQuestion && q.answer.guid.toUpperCase() != this.getDanteLegitimationGUID(legitimationData, 'LegitimationBack'))) {

          if (!q.answer || !q.answer.formData) {

            // we put fileFormData to the question, when preview for
            // document is downloaded (to skip unnecessary calls)
            if (q.fileFormData) {
              q.answer = {
                formData: q.fileFormData
              }
            } else if (q.answer && q.answer.guid) {
              q.answer = {formData: getLegitimationFileFormDataFromGuid(q.answer.guid)}
            }
          }

        }
      }
    })

    //endregion

    const skipLegitimationSynchronization = this.getStepAnswer(
      legitimationStepUID,
      buildQuestionUIDForMember("legitimation_is_valid", memberIndex, customerType)) || false

    if (!this.is_trading && !this.isNewDesign || !skipLegitimationSynchronization) {

      let customerToUpdate = (!_.isUndefined(memberIndex) && this.members[memberIndex]) ? this.members[memberIndex].id : this._customer_id;
      const customerUpdate = await UpdateCustomerData(customerToUpdate, step, undefined, extraInfo);

      // Update list of all legitimations in case new legitimation was added
      step.all_legitimations = customerUpdate.data.legitimation

      const legitimationGUID = step.question.find(q => q.uid === buildQuestionUIDForMember("legitimation['GUID']", memberIndex, customerType));
      // Update legitimation GUID (in case it was created)
      if (!legitimationGUID.answer || !customerUpdate.data.legitimation.find(l => l.GUID == legitimationGUID.answer)) {
        // find by identificationNumber in case there are other entities in CRM
        const legNumber = step.question.find(q => q.uid === buildQuestionUIDForMember("legitimation['number']", memberIndex, customerType)).answer;
        const legitimation = customerUpdate.data.legitimation.find(l => l.identificationNumber == legNumber);
        legitimationGUID.answer = legitimation.GUID;
      }

      const [frontValid, errorMessageFront] = await this._sendLegitimationDocument(customerToUpdate,
        step.question.find(q => q.uid === buildQuestionUIDForMember("legitimation['front_side']", memberIndex, customerType)),
        legitimationGUID.answer, false);

      const [rearValid, errorMessageRear] = await this._sendLegitimationDocument(customerToUpdate,
        step.question.find(q => q.uid === buildQuestionUIDForMember("legitimation['rear_side']", memberIndex, customerType)),
        legitimationGUID.answer, true);

      if (!frontValid || !rearValid) {
        // get general error from any upload or use default
        // Dante: An error occurred while uploading the legitimation document. Please try to upload the document again.
        throw errorMessageFront || errorMessageRear || 'Dante: Beim Hochladen des Legitimationsdokumentes ist ein Fehler aufgetreten. Bitte versuchen Sie das Dokument erneut hochzuladen.';
      }
    }

  }

  async __sendStepData() {
    if(LEGITIMATION_DOCUMENTS_STEP_IDENTIFIERS.includes(this._step.uid)){
      await this._sendUserLegitimationStepData(this._step, this.customer_type)
    } else {
      const shouldUpdateMembers = this.useMembers && Array.isArray(this.members) && this.members.length
      if(this._step.uid === USER_CONFIRMATION_STEP_IDENTIFIER){
        await UpdateCustomerAndSync(this._customer_id, this._step, undefined, undefined, this.isSwitchSellFlow);
      } else if (shouldUpdateMembers && COUPLE_MEMBERS_OTHER_FIELDS.includes(getUID(this._step.uid))) {
        await this.updateCustomerMemberData()
      } else {
        await UpdateCustomerData(this._customer_id, this._step);
      }
    }

    await this._validateAccount();

    return await super.__sendStepData(); // store answers in our db
  }

  async _sendLegitimationDocument(customerId, question, legitimationGUID, backside){
    let isValid = true, errorMessage;

    // if Legitimation Document has formData - send it
    if (question && question.answer && question.answer.formData){
      const formData = question.answer.formData;
      formData.append('backside', String(!!backside));

      try {
        const res = await QuestionnairesHandlerResource.at(
          `customer-legitimation-document/${customerId}/${legitimationGUID}/`).post(formData);
        // if file was sent update GUID and remove formData to prevent send it again
        question.answer = {guid: res.DokumentGUID};
      } catch (e) {
        const ERROR_MESSAGES_TRANSLATIONS = {
          413: "Die Ausweisdokumente sind zu gross.",
          502: SERVER_ERROR
        };

        const msg = _.get(ERROR_MESSAGES_TRANSLATIONS, e.status) || getErrorMessage(e.data || e);

        question.error = _.get(e, 'data.errors.file.0', msg);
        errorMessage = _.get(e, 'data.message');
        isValid = false;
      }
    }

    return [isValid, errorMessage];
  }

  _buildSteps(customer){
    const isVlPlan = this._getPrevStepAnswer('products_selection', 'product-selection-deposits', "vl_plan['checkbox']");

    return buildDataSteps(customer, isVlPlan)
  }

  __updateStepNameWithCustomer = (step, newCustomerName) => {
    let stepName = step.name.split(' ')[0];
    step.name = `${stepName} - ${newCustomerName}`
  };

  async __setQuestionnaire(onboardingAnswers) {
    await this._getBankDetails();
    let customer = await getDanteCustomerData(this._customer_id);

    this.customer_type = customer.customer_type;
    this.members = customer.relationships;
    this.current_customer = customer;

    // override PEP from User Confirm / Risk Profile
    _.set(customer, 'legitimation.0.peP',this._getPrevStepAnswer(
      this.serviceConcept == SERVICE_CONCEPTS.Anlageberatung ? "risk_profile" : "user_confirmation", "A1", "A12"));

    const preparedQuestionnaire = this._buildSteps(customer);
    // for pure sell flow we skip legitimation step
    if(this.isSellOnlyFlow){
      preparedQuestionnaire.steps = preparedQuestionnaire.steps.filter(step => !LEGITIMATION_DOCUMENTS_STEP_IDENTIFIERS.includes(step.uid))
    }

    this._questionnaire = this.__deepCopy(preparedQuestionnaire);

    this._questionnaire.steps.map((step) => {
      (step.question || []).map((q) => {

        if (COUPLE_MEMBERS_OTHER_FIELDS.includes(getUID(step.uid))) {
          const memberIndex = getMemberIndexFromStepUID(getUID(step.uid))

          if(!_.isUndefined(memberIndex) && this.members[memberIndex]) {
            setQuestionAnswerFromConfig(q, this.members[memberIndex]);
          }
        } else {
          setQuestionAnswerFromConfig(q, customer);
        }

        if(getQuestionType(q) === QUESTION_TYPE.REFERENCE_ACCOUNTS){
          q.bank_accounts = _.get(customer, 'bankAccounts', []);
          let mainBankAccount = getMainBankAccount(q.bank_accounts || {})
          q.answer = mainBankAccount ? bankAccountToOption(mainBankAccount) : undefined
        }
      })
      if(step.uid === REF_ACCOUNT_STEP_ID && !step.name){
        let stepName = this.is_trading ? "Referenzkonto/Verrechnungskonto<:placeholder> auswählen" : "Referenzkonto<:placeholder>"
        step.name = stepName.replace('<:placeholder>', this.is_couple ? " für die Gemeinschaft" : "")
      }
      // For Trading flow we have a list in this.bank, so please update get is_employer_information_mandatory flag if we add VL to Trading
      if(step.uid === 'employerInformation'){
        if(this.bank && this.bank.is_employer_information_mandatory) {
          const isEmployerInformationEmployeeIdMandatory = this.bank.is_employer_information_employee_id_mandatory;
          step.question = step.question.map((q) => ({
            ...q,
            optional: q.uid === "employer['employee_id']" && !isEmployerInformationEmployeeIdMandatory
          }))
        }
      }

      if (LEGITIMATION_DOCUMENTS_STEP_IDENTIFIERS.includes(getUID(step.uid))) {
        const memberIndex = getMemberIndexFromStepUID(getUID(step.uid))

        if(!_.isUndefined(memberIndex) && this.members[memberIndex]) {
          step.all_legitimations = this.members[memberIndex].all_legitimations
        } else {
          step.all_legitimations = customer.all_legitimations
        }
      }
    });

    this._setDefaultAnswers(onboardingAnswers);
  }

  async confirmResult(data) {
    this._is_finished = true;
  }

  getDataForAccount() {
    let answers = {};
    this._questionnaire && this._questionnaire.steps.map(step => {
      this._getStepAnswer(answers, step);
    });
    if(answers.bank_account){
      answers.bank_account = {
        ...answers.bank_account,
        credit_institute_name: answers.bank_account.creditInstituteName,
        account_holder_full_name: answers.bank_account.account_holder
      }
    }
    // For Trading flow we have a list in this.bank, so please update get is_employer_information_mandatory flag if we add VL to Trading
    if(answers.employer) {
      if(this.bank && !this.bank.is_employer_information_mandatory) {
        answers.employer = Object.entries(answers.employer).reduce((a,[k,v]) => (!v ? a : (a[k]=v, a)), {});
        if(_.isEmpty(answers.employer)) {
          delete answers.employer
        }
      }
    }

    const setTaxIdentifiers = (answers) => {
      if (answers.tax_identifiers && !_.isEmpty(answers.tax_identifiers)) {
        answers.tax_identifiers.forEach((taxIdItem, index) => {
          answers[`tax_id_${index + 1}`] = taxIdItem.tax_id;
          answers[`tax_country_${index + 1}`] = _.get(taxIdItem, 'country.id');
        })

        delete answers.tax_identifiers;
      }
    }

    setTaxIdentifiers(answers);
    const memberFieldName = getMemberFieldName(this.customer_type);
    if (!_.isEmpty(answers[memberFieldName])) {
      answers[memberFieldName].forEach((holder) => setTaxIdentifiers(holder));
    }

    return answers;
  }

  _getStepAnswer(answers, step) {
    if(step.question){
      (getStepAnswer(step).answers || []).map(answ => _.set(answers, answ.question_uid, answ.answer[0]))
    }
  }
}