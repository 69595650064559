import _ from "lodash";

import {CustomerService} from "../../customer_step_service";
import {buildDataSteps, updateStepNameWithCustomer} from "./mock_customer_data";
import {getMemberIndexFromStepUID} from "../../services";
import {getUID} from "../../utils";
import {buildQuestionUIDForMember} from "../../service";
import {LEGITIMATION_DOCUMENTS_STEP_IDENTIFIERS} from "../../constants";


export class CustomerDataServiceV2 extends CustomerService {

  async _getBankDetails() {
    // do nothing
  }

  _buildSteps(customer){
    return buildDataSteps(customer);
  }

  __updateStepNameWithCustomer = (step, newCustomerName) => {
    updateStepNameWithCustomer(step, newCustomerName)
  };

  async __setQuestionnaire(onboardingAnswers) {
    this.__is_new_design = true;

    await super.__setQuestionnaire(onboardingAnswers);

    this.__forEachSkipLegitimation((skipLegitimationQuestion, identificationStep) => {
      skipLegitimationQuestion.question_text = 'Legitimation später hinzufügen';

      if(skipLegitimationQuestion.answer) {
        identificationStep.question.forEach((question) => {
          if(question.uid !== skipLegitimationQuestion.uid) {
            question.optional = true;
            question.disabled = true;
            question.answer = null;
          }
        })
      }
    })
  }

  getDataForAccount() {
    const data = super.getDataForAccount();

    this.__forEachSkipLegitimation((skipLegitimationQuestion, identificationStep, memberIndex) => {
      if (skipLegitimationQuestion.answer) {
        const legitimationPath = buildQuestionUIDForMember('legitimation', memberIndex, this.customer_type);
        _.unset(data, legitimationPath);
      }
    });

    return data
  }

  __forEachSkipLegitimation(callback){
    LEGITIMATION_DOCUMENTS_STEP_IDENTIFIERS.forEach((uid) => {
      const identificationStep = this.getStep(uid);

      if (!identificationStep) {
        return
      }

      const memberIndex = getMemberIndexFromStepUID(getUID(identificationStep.uid));
      const skipLegitimationQuestionUID = buildQuestionUIDForMember("legitimation_is_valid", memberIndex, this.customer_type);
      const skipLegitimationQuestion = this.findQuestionDeep(identificationStep, skipLegitimationQuestionUID);
      if (skipLegitimationQuestion) {
        callback(skipLegitimationQuestion, identificationStep, memberIndex)
      }
    })
  }
}