import React from "react";
import _ from "lodash";
import clsx from "clsx";

import {makeStyles} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";

import {getStepCategory} from "../../utils";
import WarningIcon from "../../../../components/Icons/WarningIcon";

import styles from "./styles";


const NO_PARENT = "no parent";

const warningUseStyles = makeStyles(() => ({
  warnIcon: {
    fontSize: '1.15em'
  }
}));

const SideMenuWarningIcon = () => {
  const classes = warningUseStyles();

  return <>&nbsp;<WarningIcon className={classes.warnIcon}/></>
};

const SideMenu = (props) => {
  const {
    classes,
    dataService,
    answersData, // answers stored in db
  } = props;

  if (!dataService) return null;

  const isStepSelected = (step) => step.uid === _.get(dataService.step, 'uid');

  const groupedSteps = React.useMemo(() => {
    const groupedData = [];
    let lastParent;

    dataService.steps.forEach(item => {
      const parentKey = item.parent || NO_PARENT;
      if (parentKey !== lastParent) {
        groupedData.push({ parent: parentKey, selected: false, hasMissingInfo: false, items: [] });
        lastParent = parentKey;
      }
      const group = _.last(groupedData);
      // select group in case current step is inside it
      if(!group.selected) {
        group.selected = isStepSelected(item)
      }
      // in case we have stored step answers in db it was visited
      const hasMissingInfo = _.has(answersData, [dataService.uid, item.uid]) && dataService.checkHasMissingAnswers(item);
      group.items.push({step: item, hasMissingInfo});
      if(!group.hasMissingInfo) {
        group.hasMissingInfo = hasMissingInfo;
      }
    });

    return groupedData;
  }, [dataService.steps]);

  return (
    <div className={classes.menuContainer}>
      {groupedSteps.map((group, idx) => (
        <React.Fragment key={idx}>
          {group.parent !== NO_PARENT && (
            <ListItem classes={{root: classes.listItemRoot, selected: classes.listItemSelected}} selected={group.selected}>
              {group.parent}
              {group.hasMissingInfo && (
                <SideMenuWarningIcon />
              )}
            </ListItem>
          )}
          <Collapse in={group.parent === NO_PARENT || group.selected} timeout="auto" unmountOnExit>
            <List disablePadding>
              {group.items.map((groupItem) => (
                <ListItem
                  key={groupItem.step.uid}
                  classes={{
                    root: clsx(classes.listItemRoot, group.parent !== NO_PARENT && classes.listItemRootNested),
                    selected: clsx(classes.listItemSelected, group.parent !== NO_PARENT && 'nested')
                  }}
                  selected={isStepSelected(groupItem.step)}>
                  {getStepCategory(groupItem.step)}
                  {groupItem.hasMissingInfo && (
                    <SideMenuWarningIcon />
                  )}
                </ListItem>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      ))}
    </div>
  )
};

export default withStyles(styles)(SideMenu);