import React from "react";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import BaseDialog from "../../../../components/BaseDialog/BaseDialog";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import WarningIcon from "../../../../components/Icons/WarningIcon";

import useStyles from "./styles";

const MissingFieldsSummary = (props) => {
  const {
    missingFieldsData,
    navigateToPrevStep,
    handleClose,
  } = props;
  const classes = useStyles();

  const missingDataList = missingFieldsData || [];

  return (
    <BaseDialog
      title={<><WarningIcon /> Pflichtangaben fehlen</>}
      open={!!missingFieldsData}
      handleClose={handleClose}
      maxWidth={'md'}
      customClasses={classes}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.infoMsg}>Bitte vervollständigen Sie folgende Pflichtangaben:</Grid>
        {missingDataList.map((menu, idx) => (
          <>
            <Grid item xs={12} className={classes.menuContainer}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid container justify={'space-between'} alignItems={"center"}>
                    <Grid item className={classes.menuName}>{menu.name}</Grid>
                    <Grid item>
                      <PrimaryButton
                        onButtonClick={() => navigateToPrevStep(menu.uid)}
                        text={'Anzeigen'}
                        icon={<i className={'fa fa-long-arrow-right'} />}
                        iconPosition={'right'}
                        variant={'text'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {menu.steps.map(step => (
                  <Grid item xs={12} className={classes.stepContainer}>
                    <Grid container justify={'space-between'} alignItems={"center"}>
                      <Grid item className={classes.stepName}>
                        {!!step.parent && (
                          <>{step.parent}<i className={clsx('fal fa-angle-right', classes.arrowBreadcrumb)} /></>
                        )}
                        {step.name}
                      </Grid>
                      <Grid item>
                        <PrimaryButton
                          onButtonClick={() => navigateToPrevStep(menu.uid, step.uid)}
                          text={'Anzeigen'}
                          icon={<i className={'fa fa-long-arrow-right'} />}
                          iconPosition={'right'}
                          variant={'text'}
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      {step.questions.map(q => (
                        <Grid item xs={12} className={classes.missingField}>{q.question_text}</Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            {idx !== (missingDataList.length - 1) && (
              <Grid item xs={12}><Divider /></Grid>
            )}
          </>
        ))}
        <Grid item xs={12} />
      </Grid>
    </BaseDialog>
  )
};

export default MissingFieldsSummary;