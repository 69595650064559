import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  header: {
    fontSize: 24,
    margin: '0 0 0 32px',
    whiteSpace: 'nowrap',
    maxWidth: 275,

    [theme.breakpoints.down('md')]: {
      fontSize: 22,
      maxWidth: 245,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      maxWidth: 225,
    }
  },
  menuContainer: {
    maxWidth: 'calc(100% - 296px)',

    [theme.breakpoints.down('md')]: {
      maxWidth: 'calc(100% - 286px)',
    },

    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100% - 267px)',
    }
  },
  topContainer: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    height: 70,
    background: '#F5F6F7',
    color: '#80858C',
    fontFamily: 'Roboto-Bold',
    '& .menu-wrapper': {
      height: 70,
      paddingTop: 24,
    },
    '& .scroll-menu-arrow > div': {
      width: '2rem',
      lineHeight: '70px',
      verticalAlign: 'middle',
      textAlign: 'center',
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.05)',
      }
    },
    '& .scroll-menu-arrow--disabled': {
      visibility: 'hidden',
    },
    [theme.breakpoints.down('sm')]: {
      height: 100,
    }
  },
  menuItem: {
    fontSize: 14,
    margin: '0 10px',
    paddingTop: 2,
    textTransform: 'uppercase',

    '&.active': {
      color: '#0092E5',
      position: 'relative',
      '& .fa-angle-up': {
        color: '#0092E5',
        position: 'absolute',
        bottom: -18,
        fontSize: '1.7em',
        left: '50%',
        transform: 'translateX(-50%)',
        '&.has-missing-info': {
          left: 'calc(50% + 12.5px)', // ignore warn icon - to center between text
        }
      }
    },
    '&.done': {
      color: '#202A38',
      '& .fa-check-circle': {
        color: '#0092E5',
        fontSize: 20,
        verticalAlign: 'bottom',
        paddingRight: 5
      }
    },
  },
  warningIcon: {
    fontSize: 20,
    verticalAlign: 'bottom',
    paddingRight: 5
  },
  buttonContainer: {
    marginLeft: 'auto',
    marginRight: 10,
    color: '#707070',
    fontFamily: 'Roboto',
    width: 200,
    display: 'inherit',
    alignItems: 'inherit',
    justifyContent: 'inherit',
  },
  buttonCircle: {
    background: '#CCE9FA 0% 0% no-repeat padding-box',
    border: 'none',
    borderRadius: '20px',
    width: 32,
    height: 32,
    color: '#0092E5',
  },
  link: {
    margin: 0,
    position: 'relative',
    top: -10,

    '& > a': {
      color: theme.palette.primary.main,
      fontSize: 14,
      fontFamily: 'Roboto-Bold',
      textDecoration: 'none',


      '& > span:first-child': {
        fontSize: 30,
        textDecoration: 'none',
        marginRight: 5,
        position: 'relative',
        top: 4
      },
      '& > span:last-child': {
        textDecoration: 'underline'
      }
    }
  },
  deleteBtn: {
    padding: 8,
    marginLeft: 5,

    "& .MuiIconButton-label": {
      fontSize: 16
    }
  }
}));